import {
  TextInput,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  useInput,
} from 'react-admin';
import { Editor } from '@tinymce/tinymce-react';
import { Grid, FormToolbar } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

const TextArea = props => {
  const {
    input: { name, onChange },
  } = useInput(props);

  return (
    <Editor
      textareaName={name}
      initialValue={props.record ? props.record.message : ''}
      onEditorChange={onChange}
    />
  );
};

const Form = props => {
  return (
    <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
      <Grid>
        <Grid lg={6}>
          <Validation w={12}>
            <TextInput source="title" fullWidth lg={12} />
            <TextArea name="message" {...props} w={12} />
          </Validation>
        </Grid>
        <Grid lg={6}>
          <Validation w={12}>
            <SelectInput
              source="type"
              fullWidth
              lg={4}
              choices={[
                { id: 'INFO', name: 'Info' },
                { id: 'ALERT', name: 'Alert' },
              ]}
            />
            <TextInput source="link" fullWidth lg={8} />
            <div w={12}>
              <p>
                Select a specific user or send to a group of people via the
                Recipients dropdown
              </p>
            </div>
            <ReferenceInput
              sort={{ name: 'ASC' }}
              perPage={200}
              label="User"
              source="user_id"
              reference="ums/api/v1/users"
              lg={6}
            >
              <SelectInput fullWidth optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              sort={{ name: 'ASC' }}
              perPage={200}
              label="Role"
              source="recipients"
              reference="ums/api/v1/roles"
              lg={6}
            >
              <SelectInput fullWidth optionText="name" />
            </ReferenceInput>
          </Validation>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default Form;
